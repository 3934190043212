<template>
  <div class="languageView">
    <van-nav-bar :title="$t('选择语言')" left-arrow @click-left="onClickLeft" />
    <div class="hz_form">
      <van-cell  title="日本語" is-link @click="changeLanguage('ja')"></van-cell>
      <!-- <van-cell  title="简体中文" is-link @click="changeLanguage('zh')"></van-cell> -->
    </div>
  </div>
</template>
<script>
import { saveToLocal, loadFromLocal } from "@/utils/local-storage";

export default {
  name: "login",
  data() {
    return {
      zh: require("../../assets/images/zh-CN.png"),
      ja: require("../../assets/images/id-ID.png")
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go("-1");
    },
    changeLanguage(lang) {
      this.change(lang);
      this.$router.go("-1");
    }
  }
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.languageView
  .login_box
    padding: 0 0 0.8rem
    .mobile
      .van-cell
        padding: 0.26667rem 0.2rem 0.26667rem 0.42667rem
      .verify
        margin-top: 0.28rem
    h2
      color: $grayTextColor
</style>
